<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Genel Tahakkuklar"
            icon="mdi-marker-check"
            :add-route="
              can('edit-assessment')
                ? { name: 'assessments.general.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-assessment')"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            :show-delete="can('delete-assessment')"
            :delete-enabled="deleteEnabled"
            delete-tooltip="Sadece borçlandırma yapılmamış tahakkukları silebilirsiniz"
            @click:delete="handleDeleteClick"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:prepend">
              <!-- <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="BB Filtrele"
                :items="debtStatusList"
                v-model="search.debt_status"
                dense
              /> -->
            </template>
          </rs-table-title-bar>

          <rs-confirm
            ref="confirmDelete"
            :loading="isLoading"
            @confirmed="handleDeleteSubmitClick"
            @cancelled="$refs.confirmDelete.hide()"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals :headers="headers" :totals="footTotals" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'assessments.general.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.income_type="{ item, value }">
          <router-link
            :to="{
              name: 'assessments.general.show',
              params: { id: item.id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.issues_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.last_payment_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_collecting_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.remaining_amount="{ value }">
          <rs-table-cell-number price :value="value" colored reverse />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_per_house="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_deferment="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.remaining_deferment="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.enforcement_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.enforcement_collecting_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.enforcement_remaining_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.applied="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.blocks="{ value }">
          {{ value || "Tümü" }}
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters([
      "blocks",
      "cluster",
      "clusterId",
      "incomeTypeList",
      "projects",
      "debitorTypeList",
    ]),
    deleteEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        if (this.selectedItems[index].applied) {
          return false;
        }
      }

      return true;
    },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-assessment"),
        },
        {
          text: "Borçlandırma",
          value: "applied",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "Bloklar",
          value: "blocks",
          searchable: "multiselect",
          options: () => this.blocks,
          sortable: false,
        },
        {
          text: "Yıl",
          value: "year",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ay",
          value: "month",
          searchable: "number",
          align: "end",
        },
        {
          text: "Borçlandırma Tipi",
          value: "income_type",
          searchable: "select",
          options: () => this.incomeTypeList,
        },
        {
          text: "Borçlu Tipi",
          value: "debitor_type",
          searchable: "select",
          options: () => this.debitorTypeList,
        },
        {
          text: "Tahakkuk Tarihi",
          value: "issues_on",
          searchable: "date",
        },
        {
          text: "Son Ödeme Tarihi",
          value: "last_payment_on",
          searchable: "date",
          width: "120px",
        },
        {
          text: "TYA Toplam Tutar",
          value: "total_amount",
          searchable: "number",
          align: "end",
          width: "120px",
        },
        {
          text: "Ödenen Tutar",
          value: "total_collecting_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalan Tutar",
          value: "remaining_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "BB Borç Tutarı",
          value: "amount_per_house",
          searchable: "number",
          align: "end",
        },
        {
          text: "Proje",
          value: "project",
          searchable: "select",
          options: () => this.projects,
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Toplam Gecikme",
          value: "total_deferment",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalan Gecikme",
          value: "remaining_deferment",
          searchable: "number",
          align: "end",
        },
        {
          text: "İcra Edilen Tutar",
          value: "enforcement_amount",
          searchable: "number",
          align: "end",
          width: "120px",
        },
        {
          text: "İcra Tahsilat Tutar",
          value: "enforcement_collecting_amount",
          searchable: "number",
          align: "end",
          width: "120px",
        },
        {
          text: "İcra Kalan Tutar",
          value: "enforcement_remaining_amount",
          searchable: "number",
          align: "end",
          width: "120px",
        },
      ],
      search: {
        debt_status: null,
      },
      options: {
        sortBy: [],
        sortDesc: [],
      },
      titleBarAttrs: {
        exportUrl: () => `clusters/${this.clusterId}/assessments`,
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.assessment_type = 1;

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];
      const params = this.getParams();

      this.$api
        .query(`clusters/${this.clusterId}/assessments`, { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "assessments.general.edit",
          params: { id: this.selectedItems[0].id },
        });
      }
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems.length} adet tahakkuk silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteSubmitClick() {
      if (this.isLoading) return false;

      const params = {
        ids: this.selectedItems.map((item) => item.id),
      };

      this.isLoading = true;

      this.$api
        .delete("assessments", params)
        .then(() => {
          this.$toast.success("Seçilen tahakkuklar silindi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
          this.loadList();
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
